@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500&family=Roboto&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
  font-family: "Roboto", sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
}

.home-page {
  background-image: url("./Assets/ServiceTopBanner.png");
  background-color: #076fc1;
  height: 100vh;
}

.App {
  height: 100vh;
}

.font-new {
  color: #9ca3af !important;
}

.box {
  border: 1px solid #d5d5d9;
  padding: 25px;
  width: auto;
  box-shadow: 0 1.125rem 3.25rem rgba(116, 220, 233, 0.3) !important;
  background-color: #fefefe;
  border-radius: 10px;
}

.image-box-login {
  display: grid;
  place-content: center;
}

.logo-image {
  width: 150px;
  height: 50px;
}

.login-main-page-container {
  background-image: url("./Assets/bg.png");
  background-position: 100% 300px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.login-main-page {
  max-width: 1920px;
  margin: auto;
  height: 100vh;
  display: grid;
  place-items: center;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  position: absolute;
  right: 10px;
  height: 20px;
}

.form-control {
  padding-left: 30px;
}

.login-btn {
  width: 90%;
  background: #72d5eb;
  height: 50px;
  border: none;
  color: #ffff;
  border-radius: 5px;
}

.box-icon {
  border: 1px solid #d5d5d9;
  padding: 10px;
  width: auto;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
  border-radius: 2px;
}

.login-mobile-logo {
  display: none;
}

@media screen and (max-width: 990px) {
  .box {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .login-mobile-logo {
    display: block !important;
  }

  .logo-image {
    width: 100px;
    height: 50px;
  }

  .survey-answers {
    flex-direction: column;
  }

  .ack-que-head {
    flex-direction: column;
  }
}

@media screen and (min-width: 450px) and (max-width: 768px) {
  .box {
    margin: 0 10%;
    margin-bottom: 10px;
  }
}

/* Service Css */

/* .service-top {
  position: relative;
  width: 100vw;
  height: 60vh;
}

.service-top .upper-img {
  position: absolute;
  background-color: #076fc1;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.service-bottom {
  position: relative;
  width: 100vw;
  height: 40vh;
}

.service-bottom .lower-img {
  position: absolute;
  background-color: #076fc1;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: 100%;
} */

.logout-btn {
  background: linear-gradient(45deg, #459fff 2.41%, #6de5ff 104.46%);
  height: 50px;
  border: none;
  color: #ffff;
  border-radius: 5px;
  padding: 10px 20px;
}

.service-conatiner-box {
  border: 1px solid #d5d5d9;
  padding: 50px;
  width: auto;
  box-shadow: 0 1.125rem 3.25rem rgba(116, 220, 233, 0.3) !important;
  background-color: #fefefe;
  border-radius: 10px;
  margin-bottom: 50px;
}

.service-box {
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  width: auto;
  padding: 15px;
  margin: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.service-box-active {
  background: linear-gradient(45deg, #459fff 2.41%, #6de5ff 104.46%);
  box-shadow: 0px 10px 30px 0px #076fc14d;
}

.facility-select {
  background-color: transparent !important;
  border: 1px solid #459fff !important;
  /* border-image-source: linear-gradient(45deg, #459FFF 2.41%, #6DE5FF 104.46%) !important; */
  color: #ffff !important;
}

.facility-select option {
  background-color: #459fff !important;
  color: #ffff !important;
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .service-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .services {
    margin-top: 100px !important;
  }

  .select-input-service {
    width: 80%;
  }
}

/* Patient survey page css */

.patient-main-page {
  max-width: 1920px;
  height: 100vh;
  margin: auto;
}

.patient-survey-page {
  background-image: url("./Assets/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.patient-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  /*  height: 90vh;*/
}

.patientbox {
  border: 1px solid #d5d5d9;
  padding: 10px;
  text-align: center;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 1.125rem 3.25rem rgba(116, 220, 233, 0.3) !important;
  background-color: #fefefe;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
}

.patient-survey-select {
  width: 25%;
  float: right;
}

@media (max-width: 575px) {
  .patient-survey-select {
    width: 45%;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .box {
    max-width: 100%;
  }
}

.next-btn {
  background: linear-gradient(45deg, #459fff 2.41%, #6de5ff 104.46%);
  color: white !important;
  border: none !important;
  padding: 10px 30px !important;
}

.question-box {
  height: auto;
  width: 60%;
}

.acknowledge-box {
  height: auto;
  width: 70%;
  border: 1px solid #d5d5d9;
  padding: 30px;
  box-shadow: 0 1.125rem 3.25rem rgba(116, 220, 233, 0.3) !important;
  background-color: #fefefe;
  border-radius: 10px;
}

.ack-buttons {
  width: 160px;
  height: 60px;
}

@media (max-width: 800px) {
  .question-box {
    height: auto;
    width: 90%;
  }

  .next-btn {
    padding: 5px 15px !important;
  }

  .employee-review-list {
    display: flex;
    justify-content: center;
  }
}

.linkbox {
  text-decoration: none;
  color: black;
}

.ack-que-head {
  display: flex;
  column-gap: 20px;
}

.ack-left-arrow {
  height: 30px;
  width: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  background: #e3dfdf none repeat scroll 0 0;
  padding: 5px 8px;
  border-radius: 60%;
}

.bg-full {
  width: 100%;
  height: 100%;
}

@media (max-width: 400px) {
  .service-conatiner-box {
    padding: 50px 0 !important;
  }

  .arrow-image {
    display: none;
  }
}

.navbar {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: white;
}
